<template>
  <div class="co-flex-col py-8">
    <div
      v-if="isBindMobile"
      class="co-flex-col px-8"
    >
      <span class="text-h5 my-6">{{ bindTips.title }}</span>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-text-field
          v-model="txtPhone.value"
          :label="txtPhone.label"
          outlined
          required
          :prepend-inner-icon="txtPhone.prependInner"
          :rules="phoneRules"
        />
        <v-text-field
          v-model="txtCode.value"
          :label="txtCode.label"
          outlined
          required
          :prepend-inner-icon="txtCode.prependInner"
          :rules="codeRules"
        >
          <template #append>
            <span
              class="pt-1"
              :class="[txtCode.color ? `${txtCode.color}--text` : 'blue-grey--text']"
              style="cursor: pointer;"
              @click="sendMsgCode"
            >
              {{ txtCode.appendInner }}
            </span>
          </template>
        </v-text-field>

        <span class="text-subtitle-1 my-6">{{ bindTips.hint }}</span>
        <v-btn
          :loading="btnBind.loading"
          :color="btnBind.color"
          class="co-w-full mt-2"
          height="48"
          @click="toBind"
        >
          {{ btnBind.text }}
        </v-btn>
      </v-form>
    </div>
    <div
      v-else
      class="co-flex-col co-justify-center co-items-center"
    >
      <span class="text-h5 my-4">{{ title }}</span>

      <v-img
        v-if="qrcode"
        :src="qrcode"
        width="220"
        height="220"
      >
        <div
          v-if="tkExpirted"
          class="co-flex-col co-justify-center co-items-center qrcode-expired-bg co-w-full co-h-full rounded"
          style="cursor: pointer;"
          @click="reloadQrcode"
        >
          <span class="white--text">二维码已失效</span>
          <span class="white--text mt-6">点击重新加载</span>
        </div>
      </v-img>

      <span class="text-body-2 blue-grey--text">{{ scanTip }}</span>

      <div class="co-flex-row co-items-center mt-10">
        <div class="co-h2 co-w56 co-border-t co-border-gray-400" />
        <span class="text-body-2 blue-grey--text mx-5">{{ mdPhone.tip }}</span>
        <div class="co-h2 co-w56 co-border-t co-border-gray-400" />
      </div>

      <div class="co-flex-col co-justify-center co-items-center mt-6">
        <v-avatar
          color="primary"
          size="48"
          style="cursor: pointer;"
          @click="toChange"
        >
          <v-icon color="white">
            {{ mdPhone.icon }}
          </v-icon>
        </v-avatar>
      </div>
    </div>
  </div>
</template>

<script>
  import api from '@/api/co.api'
  import app from '@/api/co.app'
  import lib from '@/api/co.lib'
  import web from '@/api/web/co.web'
  import user from '@/api/co.user'

  export default {
    name: 'LoginQrcode',
    props: {},
    data () {
      return {
        qrcode: '',
        title: '欢迎来到我遇见最美的光',
        scanTip: '打开微信扫一扫',
        mdPhone: {
          tip: '通过手机号码登录',
          icon: 'mdi-cellphone',
          mode: 'phone'
        },
        ticket: {},
        tkTimer: null,
        tkCoutner: 0,
        tkExpirted: false,
        tokenInfo: {},
        isBindMobile: false,
        bindTips: {
          title: '绑定手机号码',
          hint: '防止出现多个账号请绑定手机号码'
        },
        txtPhone: {
          label: '手机号码',
          prependInner: 'mdi-cellphone',
          appendInner: '',
          value: ''
        },
        txtCode: {
          label: '验证码',
          prependInner: 'mdi-shield-key-outline',
          appendInner: '获取验证码',
          value: '',
          timer: null,
          count: 60,
          disabled: false,
          color: 'black'
        },
        btnBind: {
          loading: false,
          color: 'primary',
          text: '绑定'
        },
        phoneRules: [
          val => !!val || '必须填写手机号码',
          val => /^[1][3-9][0-9]{9}$/.test(val) || '请输入正确的手机号码'
        ],
        codeRules: [
          val => !!val || '必须填写验证码',
          val => /^[0-9]{6}$/.test(val) || '请输入正确的验证码'
        ],
      }
    },
    created () {
      this.getQrcode()
    },
    destroyed () {
      this.closeTicketTimer()
    },
    methods: {
      clearCodeTimer () {
        if (this.txtCode.timer) {
          clearInterval(this.txtCode.timer)
          this.txtCode.timer = null
        }
      },
      startCodeTimer () {
        const me = this
        const decreaseCount = function () {
          me.txtCode.count -= 1
          if (me.txtCode.count < 1) {
            me.clearCodeTimer()
            me.txtCode.appendInner = '获取验证码'
            me.txtCode.disabled = false
            me.txtCode.color = 'black'
          } else {
            me.txtCode.appendInner = `(${me.txtCode.count}S)后重发`
          }
        }

        this.txtCode.count = 60
        this.txtCode.disabled = true
        this.txtCode.color = 'grey'
        this.txtCode.timer = setInterval(decreaseCount, 1000)
      },
      sendMsgCode () {
        const me = this
        const mobile = this.txtPhone.value
        if (!mobile) {
          me.$notify({
            title: '错误提示',
            message: '请输入手机号码',
            type: 'error'
          })
          return
        }

        const executing = function () {
          me.startCodeTimer()
        }

        const executed = function (res) {
          // console.log('sendMsgCode, res: %o', res)
          if (res.status) {
            me.$message({
              message: '验证码发送成功！',
              type: 'success'
            })
          } else {
            me.$message({
              message: '验证码发送失败！',
              type: 'error'
            })
          }
        }

        lib.sso.sendMsgCode({
          mobile,
          executing,
          executed
        })
      },
      startTicketTimer () {
        const me = this
        let counter = 0
        if (!this.tkTimer) {
          this.tkTimer = setInterval(() => {
            me.checkExpired()
            counter++
            if (counter > 2) {
              me.checkQrcode()
              counter = 0
            }
          }, 1000)
        }
      },
      closeTicketTimer () {
        if (this.tkTimer) {
          clearInterval(this.tkTimer)
          this.tkTimer = null
          this.tkCoutner = 0
        }
      },
      saveToken () {
        const me = this
        const callback = function (res) {
          // console.log('saveToken, res: %o', res)
          if (res.status) {
            const data = res.data || {}
            if (data.mobile) {
              me.isBindMobile = false
              app.emit(app.event.USER_LOGIN, res.data)
            } else {
              me.isBindMobile = true
            }
          }
        }

        user.token.save(this.tokenInfo, callback)
      },
      checkQrcode () {
        const me = this
        const executed = function (res) {
          // console.log('checkQrcode, res: %o', res)
          if (res.status) {
            const data = res.data || {}
            const accessTokenInfo = data.accessTokenInfo || ''
            if (accessTokenInfo) {
              me.tokenInfo = api.comm.jsonToObject(accessTokenInfo)
              // console.log('checkQrcode, tokenInfo: %o', me.tokenInfo)
              if (me.tokenInfo.accessToken) {
                me.closeTicketTimer()
                me.saveToken()
              }
            }
          }
        }

        web.wxmp.checkQrcode({
          qrcodeId: this.ticket.qrcodeId,
          executed
        })
      },
      checkExpired () {
        this.tkCoutner++
        if (this.tkCoutner >= this.ticket.invalidTime) {
          this.closeTicketTimer()
          this.tkExpirted = true
        }
      },
      toChange () {
        this.$emit('change', {
          ...this.mdPhone
        })
      },
      getQrcode () {
        const me = this
        const executing = function () {
          me.tkExpirted = false
        }
        const executed = function (res) {
          // console.log('getQrcode, res: %o', res)
          if (res.status) {
            const data = res.data || {}
            me.ticket = Object.assign({}, data)
            me.qrcode = data.qrcodeLink || ''
            me.startTicketTimer()
          }
        }

        web.wxmp.getTicket({
          invalidTime: 180,
          executing,
          executed
        })
      },
      reloadQrcode () {
        const me = this
        const executed = function (res) {
          me.getQrcode()
        }

        web.wxmp.expiredQrcode({
          qrcodeId: this.ticket.qrcodeId,
          executed
        })
      },
      toBind () {
        if (!this.$refs.form.validate()) {
          return
        }

        const me = this
        const mobile = this.txtPhone.value
        const code = this.txtCode.value

        const executing = function () {
          me.btnBind.loading = true
        }

        const executed = function (res) {
          // console.log('toBind, status: %o, data:%o', res.status, res.data)
          me.btnBind.loading = false
          if (res.status) {
            const data = res.data
            user.info.save(data)
            user.token.set({
              userId: data.userId,
              username: data.username
            })
            app.emit(app.event.USER_LOGIN, data)

            me.$notify({
              title: '成功提示',
              message: '手机号绑定成功！',
              type: 'success'
            })
          } else {
            me.$notify({
              title: '手机号绑定失败',
              message: `${res.data.code} - ${res.data.desc}`,
              type: 'error'
            })
          }
        }

        lib.sso.bindMobile({
          mobile,
          code,
          executing,
          executed
        })
      }
    }
  }
</script>

<style>
  .qrcode-expired-bg {
    background-color: rgba(0, 0, 0, 0.6);
  }
</style>
